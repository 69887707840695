header.header{
    background-color: rgb(255, 255, 255);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    gap: 20px;
    /* flex-wrap: wrap; */
}
.title-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 90px;
    row-gap: 10px;
    flex-wrap: wrap;
}
.title-wrap .title{
    color: #242424;
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
}
.search-wrap Button{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 297px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #F2F7FA;
    border: none;
    color: #454545;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.search-wrap .search-icon{
    font-size: 14px;
}
.right-buttons-wrap{
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
}
.right-buttons-wrap .right-icons{
    font-size: 24px;
    color: #242424;
}