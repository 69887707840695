.wrapper{
    display : flex;
    position : relative;
}
.wrapper .sider{
    position: fixed;
    width: 252px;
    height: 100vh;
}
.wrapper .main{
    width: 90%;
    height: 100%;
    padding-top:50px;
    margin-left: 200px;
}