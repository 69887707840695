.geo-section{
    flex-wrap: nowrap;
}
.geo-wrap,.legend-list-wrap{
    width: 50%;
}
.geo-wrap,.legend-list-wrap .chart-card-title{
    text-align: end;
}
.weeklyloan-chart{
    padding: 20px 40px;
}
.legend-wrap{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-end;
}
.legend-wrap .row-box{
    flex-wrap: nowrap;
    gap: 10px;
}
.legend-wrap .row-data-a .legend-circle,.legend-wrap .row-data-a .label-box{
    background: #DDFFBB;
}
.legend-wrap .row-data-b .legend-circle,.legend-wrap .row-data-b .label-box{
    background-color: #C7E9B0;
}
.legend-wrap .row-data-c .legend-circle,.legend-wrap .row-data-c .label-box{
    background-color: #AFC6AA;
}
.legend-wrap .row-data-d .legend-circle,.legend-wrap .row-data-d .label-box{
    background-color: #A4BC92;
}
.legend-wrap .row-data-e .legend-circle,.legend-wrap .row-data-e .label-box{
    background-color: #73925B;
}
.legend-wrap .row-data-a{
    width: 40%;
}
.legend-wrap .row-data-b{
    width: 50%;
}
.legend-wrap .row-data-c{
    width: 60%;
}
.legend-wrap .row-data-d{
    width: 70%;
}
.legend-wrap .row-data-e{
    width: 80%;
}
.legend-wrap .legend-circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.legend-wrap .label-box{
    padding: 10px 20px;
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.98px;
    width: 100%;
    border-radius: 50px 0 0 50px;
}
.ant-popover .content-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ant-popover .content-wrap .state-name{
    color: #333;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}
.ant-popover .content-wrap .user-group,.ant-popover .content-wrap .amount{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #333;
    font-size: 12px;
    font-weight: 500;
}
.weeklyloanschart .recharts-tooltip-wrapper .tooltip-wrap{
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.weeklyloanschart .recharts-tooltip-wrapper .tooltip-wrap p{
    margin: 0;
}
.weeklyloanschart .recharts-tooltip-wrapper .tooltip-wrap .amount{
    color: #099b8f;
}
.weeklyloanschart .recharts-tooltip-wrapper .tooltip-wrap .count{
    color: #d14646;
}