* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.grid-gap {
    margin-top: 35px;
    padding: 0 40px;
}

.grid-gap-two {
    width: auto;
    margin-top: 35px;
    padding: 0 20px 0 40px;
}

.stick-the-header{
    position: sticky;
    top:0;
    z-index: 101;
}

.dash-headbar {
    height: 70px;
    background-color: #1A0045;
    position: sticky;
    top: 0;
    z-index: 100;
}

.dash-headbar-inner-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    width: auto;
}

.dash-header {
    font-weight: 400;
    font-size: 30px;
    line-height: 70px;
    color: #ffffff;
}

.dash-header-span {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    /* margin-left: 214px; */
    padding-left: 209px;
    color: #963460;
}

.dash-icons-bar {
    display: flex;
    justify-content: space-evenly;
    width: 10vw;
}

.icons {
    color: #ffffff;
    font-size: 20px;
    line-height: 93px;
    cursor: pointer;
}

.dash-greeting {
    background-color: #D0CCFF;
    height: 95px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    border-radius: 4px;
    box-sizing: border-box;
}

.dash-greeting-inline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.greet-header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    color: #444445;
    padding-top: 40px;
    width: auto;
}

.greet-body {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #444445;
    padding-bottom: 40px;
}

.time-zone {
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 32px 3px rgba(105, 108, 180, 0.16);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 0 30px;
}

.date, .time {
    display: flex;
}

.dticons {
    color: #A098FF;
    font-size: 18px;
    font-weight: 600;
    font-size: 20px;
}

.dttext {
    color: #A098FF;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.graph-one-card {
    height: 565px;
    background: #FFFFFF;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
    width: auto;
}

.graph-one-card-header {
    width: 90%; 
    display: flex;
    justify-content: space-between;
}

.second-row-card {
    height: 365px;
    background: #FFFFFF;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 16px;
    width: 95%;
    position: relative;
}

.loan-offers-two-by-two-card {
    height: 365px;
    background: #FFFFFF;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 16px;
    width: 97.5%;
    position: relative;
}

.third-row-card {
    height: 365px;
    background: #FFFFFF;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 16px;
    width: 990px;
    position: relative;
}
.sixthCard{
    height: 365px;
    background: #FFFFFF;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 16px;
    width: 490px;
    position: relative;
    left: 30px;
}
.g-one-header {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #444445;
}

.g-one-subheader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #444445;
}

.ledgers {
    /* background-color: yellow; */
    /* height: 20px; */
    display: flex;
    justify-content: space-between;
    width: 40vw;

}

.ledg-circle-one {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #2DB6F5;
}

.ledg-circle-two {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #39C86A;
}

.ledg-circle-three {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FF5722;
}

.ledg-first-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ledg-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #444445;
    display: flex;
    align-items: center;
}

.right-info-card {
    width: 250px;
    height: 250px;
    position: absolute;
    right: 120px;
    top: 500px;
}

.right-info-card-label {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #444445;
}

#year {
    background: #FCFCFD;
    height: 32px;
    width: 130px;
    border: 1px solid #CFD0D7;
    border-radius: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #444445;
}

.go-button {
    width: 54px;
    height: 32px;
    background: #4E41D9 !important;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.08em;
    border: 0;
}

.int-collected {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    padding: 20px 0 0 0;
}

.int-collected-sum {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FE642E;
    display: flex;
    align-items: center;
    justify-content: center;
}

.g-two-head {
    padding-left: 20px;
    padding-top: 20px;
}

.g-two-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #444445;
}

.g-two-subheader {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #5A5DF6;
    display: flex;
    align-items: center;
}

.g-five-six-parent {
    width: 100%;
    height: 40px;
    padding: 0 45px;
    display: flex;
    justify-content: flex-end;
}

#month {
    background: #FFFFFF;
    height: 32px;
    width: 100px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #263238;
    border: 0;
}

.tranche-requests-day {
    position: absolute;
    right: 50px;
    top: 53px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #868686;
}

.graph-eight-nine-card {
    height: 388px;
    background: #FFFFFF;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
}

.g-nine-header {
    width: 100%;
    display: flex;
    /* background-color: #39C86A; */
    justify-content: space-between;
}

.g-nine-head-left-sec {
    display: flex;
    justify-content: space-between;
    width: 35vw;
}

.g-nine-head-interest {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #868686;
}

.g-nine-head-right-sec {
    display: flex;
    width: 20vw;
    /* background-color: turquoise; */
    justify-content: space-between;
    align-items: center;
}

#g-nine-select-month {
    font-weight: 400;
    font-size: 16px;
    background-color: #ffffff;
    letter-spacing: 0.44px;
    color: #000000;
    width: 96px;
    border: 0;
}

.g-nine-settings-icon {
    color: #444445;
    font-size: 20px;
}
.vector-image {
    /* display: flex; */
    position: relative;
    top: 3px
}
@media screen and (max-width: 1350px) {
    .greet-header{
        font-weight: 300;
        font-size: .8rem;
        line-height: 10px;
    }
    .greet-body{
        font-weight: 300;
        font-size: 8px;
        line-height: 10px; 
    }
    .vector-image {
        display: none;
    }
    .graph-one-card {
        position: relative;
        height: 565px;
    }
    .right-info-card {
        width: 15vw;
        height: 250px;
        position: absolute;
        right: 5px;
        top: 150px;
    }
    #year {
        margin: 10px 0;
    }

    .go-button {
        display: block;
        margin-left: 50px;
        margin-right: auto;
    }
}
@media only screen and (max-width: 1200px) {
    .dash-header-span {
        font-weight: 600;
        font-size: 20px
    }
  }