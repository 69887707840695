* {
  font-family: 'Poppins';
  font-style: 'normal';
}

.main {
  background-color: #FFFFFF;
  width: 98vw;
  height: 98vh;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .MuiButton-outlinedPrimary {
  color: #3f51b5;
  margin-right: 15px;
  border: 1px solid rgba(63, 81, 181, 0.5);
} */

*:disabled {
  background-color: dimgrey;
  color: linen;
  opacity: 0.6;
  cursor: not-allowed;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}