#sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
  top: 0px;
  background-color: #1A0045;
  z-index: 500;
}

.logotext {
  height: 180px;
  width: 250px;
  position: fixed;
  top: 80px;
}

.avatar {
  margin-left: 70px;
  margin-right: 70px;
}

#atlas {
  margin-left: 55px;
  margin-right: 55px;
  font-family: Red Rose;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.24em;
  text-align: left;
  color: #963460;
  position: relative;
  top: 10px;
}

#admin {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.08em;
  color: #FFFFFF;
  margin-top: -15px;
  margin-left: 88px;
}
/* 
.ant-menu {
  border-top-right-radius: 90px;
  margin-top: 275px;
  width: 250px;
  padding-top: 20px;
  font-size: 16px;
}

.ant-menu .ant-menu-item {
  margin-top: -18px;
  height: 54px;
  margin-left: 0 !important;
}

.ant-menu-submenu-title {
  margin-top: -14px !important;
  margin-bottom: 10px !important;
  color: #ffffff;
}

.ant-menu-item {
  margin-top: -10px;
}

.ant-menu ul {
  position: relative !important;
  height: auto !important;
} */

.link {
  color: #ffffff !important;
}

.active {
  color: #e74c3c !important;
}

#sidebar .pro-sidebar-content {
  height: 477px;
  position: fixed;
  top: 30px;
}

.pro-sidebar-header {
  position: fixed;
  width: 100vw;
  z-index: 1000;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #2D105E;
  height: calc(100vh - 275px);
  overflow-y: auto;
  overflow-x: hidden;
}

#sidebar .closemenu {
  color: #ffffff;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 5px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#sidebar .pro-sidebar.collapsed {
  width: 150px;
  min-width: 80px;
}

#sidebar .pro-sidebar-inner {
  background-color: #2D105E;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  margin: 0 0 0 5px;
  font-size: 20px;
  padding: 10px 15px;
  text-align: center;
  color: #F0F2F5;
  font-weight: bold;
  border-radius: 20px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 10px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .menu {
  color: rgb(253, 251, 251);
  padding: 10px;
  text-align: center;
  text-decoration: none;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .navlink {
  text-decoration: none;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .icon {
  color: rgb(253, 251, 251);
  margin: 10px 0px;
  text-align: center;
  font-weight: bold;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  color: rgb(252, 248, 248);
  border-radius: 3px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark {
  height: 100vh;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

@media only screen and (max-width: 953px) {
  #sidebar {
    height: auto;
  }

  /* .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    height: 400vh;
  } */
}

@media only screen and (max-width: 1200px) {

  /* #sidebar .pro-sidebar-content{
    top: -80px;
  } */
  .ant-menu {
    font-size: 12px;
  }

  /* #atlas, #admin{
    display: none;
  } */
  .ant-menu-item {
    height: 34px;
  }
}