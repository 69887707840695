.application-status-section .application-status-card .cards-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 20px;
    gap: 20px;
    grid-auto-flow: row;
}
.application-status-section .application-status-card .cards-container .card{
    padding: 20px 30px;
    border-radius: 16px;
    min-height: 132px;
    gap: 20px;
}
.application-status-section .application-status-card .cards-container .card.revenue{
    border: 1px solid #6FC3CE;
    background: linear-gradient(270deg, rgba(163, 245, 134, 0.43) 0%, rgba(171, 233, 205, 0.48) 100%);
}
.application-status-section .application-status-card .cards-container .card.tranches{
    border: 1px solid #CFADCF;
    background: linear-gradient(268deg, rgba(254, 226, 248, 0.74) 0%, rgba(254, 226, 248, 0.81) 0.01%, #DCF8EF 100%);   
}
.application-status-section .application-status-card .cards-container .card.received{
    border: 1px solid #8AE9B3;
    background: linear-gradient(90deg, rgba(200, 214, 229, 0.64) 0%, rgba(138, 233, 179, 0.25) 100%);
}
.application-status-section .application-status-card .cards-container .card .card-header{
    color: #414141;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
}
.application-status-section .application-status-card .cards-container .card .card-header .rupee{
    font-size: 26px;
}
.application-status-section .application-status-card .cards-container .card .card-content{
    color: #414141;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.9%;
    letter-spacing: 1.26px;
    word-break: break-word;
}
.application-status-section .application-status-chart .application-chart-wrap{
    flex-wrap: nowrap;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container .card{
    padding: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.17);
    max-width: 250px;
    width: 100%;
    gap: 10px;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container .card.approved{
    border: 3px solid #CADEFC;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container .card.rejected{
    border: 3px solid #CCA8E9;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container .card.pending{
    border: 3px solid #9DDCDC;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container .card .card-title{
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.98px;
    word-break: break-word;
}
.application-status-section .application-status-chart .application-chart-wrap .card-container .card .card-value{
    color: #454544;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .empty-pie{
    /* background-image: url('../../../assets/images/empty-pie-chart.svg');
    width: 70%;
    height: 400px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .empty-pie .statistic-wrap{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .empty-pie .statistic-wrap .headtitle{
    color: #454544;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .empty-pie .statistic-wrap .st-value{
    color: #454544;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .legend-wraper .legends{
    display: flex;
    align-items: center;
    gap: 10px;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .legend-wraper .leg-square{
    width: 20px;
    height: 20px;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .legend-wraper .leg-approved .leg-square{
    background: #CADEFC;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .legend-wraper .leg-rejected .leg-square{
    background: #CCA8E9;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .legend-wraper .leg-pending .leg-square{
    background: #9DDCDC;
}
.application-status-section .application-status-chart .application-chart-wrap .application-chart .legend-wraper .leg-label{
    color: #242424;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}