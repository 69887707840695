.creditline-card .data-card-wrap{
    background: #FFEEF2;
    align-items: stretch;
}
.creditline-card .data-card-wrap .filter-select{
    display: flex;
    align-items: center;
}
.creditline-card .data-card-wrap .card{
    border-radius: 6px;
    padding: 10px;
    min-height: 60px;
    /* max-width: 157px; */
    background: #ffffff;
}
.creditline-card .data-card-wrap .card.sanctioned{
    border: 1px solid #F69D9D;
}
.creditline-card .data-card-wrap .card.active-credit{
    border: 1px solid #FFC4D0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.creditline-card .data-card-wrap .displayflex{
    gap: 10px;
}

.creditline-card .data-card-wrap .card-sub-title{
    margin: 0;
    color: #8A6969;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.56px;
    word-break: break-word;
}
.creditline-card .recharts-tooltip-wrapper .tooltip-wrap{
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.creditline-card .recharts-tooltip-wrapper .tooltip-wrap p{
    margin: 0;
}
.creditline-card .recharts-tooltip-wrapper .tooltip-wrap .creditline-use-wrap,.creditline-card .recharts-tooltip-wrapper .tooltip-wrap .active-credit-wrap{
    display: flex;
    align-items: center;
    gap: 10px;
}
.creditline-card .recharts-tooltip-wrapper .tooltip-wrap .tooltipcircle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.creditline-card .recharts-tooltip-wrapper .tooltip-wrap .active-credit-wrap .tooltipcircle{
    background-color: #F69D9D;
}
.creditline-card .recharts-tooltip-wrapper .tooltip-wrap .creditline-use-wrap .tooltipcircle{
    background-color: #FFC4D0;
}