#partnerSection{
    width: 100vw;
    height: 100vh;
    background-color: #1A0045;
    display: flex;
    padding: 0;
}
#partnerSection #sidebar{
    margin-left: 10px;
    width: 10%;
    height: 100%;
    background-color: #1A0045;
    display: inline-block;
    position: relative;
}
#partnerSection #card {
    margin-left: 25%;
    margin-right: 30%;
    margin-top: 3%; 
    padding: 25px 50px 75px 15px;
    position: absolute ;
    width: 70%;
    height: 88%;
    background: white !important;
    border-radius: 18px;
}
#partnerSection #sidebar #avatar{
    margin-left: 90px;
    margin-top: 50px;
    display: inline-block;
}
#partnerSection #sidebar #atlas {
    font-family: 'Red Rose';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    color: #963460;
    line-height: 50px;
    letter-spacing: 0.24em !important;
    text-align: center;
    margin-left: 54px;
    margin-top: 70px;
}
#partnerSection #sidebar #img{
    margin: 10px 0px 0px 5px;
}
#partnerSection  #sidebar #adminLabel {
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 0px !important;
    letter-spacing: 0.08em;
    text-align: center;
    margin-left: 110px;
    margin-top: 0px;
}
#partnerSection #partnerLabel {
    color: #963460;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.10em;
    text-align: center;
    margin-left: 70px;
    margin-top: 100px;
}
#partnerSection #grid{
    padding: 10px 10px 10px 10px;
}
@media screen and (max-width: 1000px){
   #partnerSection #card{
        margin-left: 30%;
    }
}
@media screen and (max-width: 800px){
    #partnerSection #card{
         margin-left: 40%;
         padding: 10px 10px 10px 10px;
     }
 }