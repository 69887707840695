.preapproved-card .data-card-wrap{
    align-items: center;
    background: #DCF5F5;
}
.preapproved-card .data-card-wrap .card{
    border-radius: 6px;
    border: 1px solid #9DDCDC;
    padding: 10px;
    min-height: 54px;
    background: #ffffff;
}
/* .preapproved-card .data-card-wrap .card.users{
    max-width: 130px;
} */
.preapproved-card .data-card-wrap .card .displayflex{
    gap: 7px;
    flex-wrap: nowrap;
}
.preapproved-card .data-card-wrap .card.users h3.card-title{
    font-weight: 500;
    display: block;
}
.preapproved-card .data-card-wrap .card.users h3.card-title span{
    font-weight: 700;
    color: #027878;
}