.welcome .section-wrap .welcome-card .wl-title{
    color: #BB6A93;
    font-size: 21.494px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 2px 0;
}
.welcome .section-wrap .welcome-card .wl-title span{
    font-weight: 900;
}
.welcome .section-wrap .welcome-card .wl-subtitle{
    color: #444445;
    font-size: 14.329px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 2px 0;
}
.welcome .section-wrap .welcome-trend-card .welcome-cards .card{
    min-width: 300px;
    min-height: 128px;
    border-radius: 20px;
    padding: 20px;
}
.welcome .section-wrap .welcome-trend-card .header-actions .btns{
    padding: 10px;
    gap: 10px;
    border-radius: 6px;
    background: #EDEBFB;
    border: none;
    cursor: pointer;
}
.welcome .section-wrap .welcome-trend-card .header-actions .active-month, .welcome .section-wrap .welcome-trend-card .header-actions .btns:hover{
    border: 1px solid #7765F9;
}
.welcome .section-wrap .welcome-trend-card .welcome-cards{
    margin: 20px 0;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    gap: 20px;
    grid-auto-flow: row; 
}
.welcome .section-wrap .welcome-trend-card .cards .loan-user{
    background: rgba(254, 200, 216, 0.3)
}
.welcome .section-wrap .welcome-trend-card .cards .credit-line{
    background: rgba(195, 190, 240, 0.3)
}
.welcome .section-wrap .welcome-trend-card .cards .revenue{
    background: rgba(197, 236, 190, 0.3)
}
.welcome .section-wrap .welcome-trend-card .cards .displayflexcol.contentwrap{
    gap: 10px;
}
.welcome .section-wrap .welcome-trend-card .cards .card-title{
    color: #263238;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.welcome .section-wrap .welcome-trend-card .cards .card-subtitle{
    color: #444445;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.welcome .section-wrap .welcome-trend-card .cards svg{
    cursor: pointer;
}